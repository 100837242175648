body {
  background-color: #f5f6f8 !important;
  overflow-y: overlay;
}
.appmaker-logo {
  width: 166px;
}
.step-box::before {
  content: "";
  position: absolute;
  top: -15px;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0);
}

/* Step 1 */

.step-box-1::before {
  left: 40px;
}
.nav-bullets {
  height: 90px !important;
  position: relative;
  padding: 32px 0 60px 0;
  left: 43px;
}
.nav-bullets li a {
  width: 40px;
  display: inline-block;
}
.nav-bullets li a::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #4b6efc;
  border-radius: 50%;
  opacity: 0.3;
}
.nav-bullets li.active a::after {
  opacity: 1;
}
/* URL Validation step */
.step-box-url-validation::before {
  left: 80px;
}
.step-box-url-validation > .url-validation-box small {
  display: block;
}
.step-box-url-validation .url-validation-container {
  min-height: 323px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Choose Method */
.step-box-chooseMethod::before {
  left: 120px;
}
.choose-method a.dashed-container {
  border: rgb(206, 212, 218) dashed 1px;
  border-radius: 5px;
  position: relative;
}
.choose-method a.dashed-container:hover {
  border: #4b6efc solid 1px;
  background: #f5f6f8;
}
.choose-method .learnmore {
  position: absolute;
  right: 25px;
  bottom: 20px;
}
.tooltip-arrow[data-placement*="bottom"]::after {
  border-color: transparent transparent #31454e transparent !important;
  border-width: 0 0.5rem 0.4rem 0.5rem !important;
}
/* Book demo */
.react-tel-input input {
  width: 100% !important;
}
.custom-checkbox,
.custom-radio {
  border: 1px solid #dee2e6;
}
.custom-checkbox input:checked + label {
  color: #4b6efc !important;
}
.custom-radio input:checked + label {
  color: #4b6efc !important;
}

/* Step-2 appname and logo */

.step-box-2::before {
  left: 160px;
}
.applogo-input {
  border: 1.2px dashed rgb(206, 212, 218);
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer !important;
}
.applogo-input input {
  padding: 4rem 0;
}
.plus-icon {
  position: absolute;
  color: rgb(206, 212, 218);
  right: 30%;
  top: 30%;
  text-align: center;
}
.plus-icon i {
  font-size: 2rem;
}

/* thank you page */
.step-box-thank::before {
  left: 200px;
}

/* web app */

.preview-box {
  background-color: #3381ff18;
  padding: 1rem;
  border-radius: 8px;
  min-height: 135px;
}
.box_input {
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_input label {
  margin-bottom: 0;
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  flex-direction: column;
}
.loading-container img {
  border-radius: 1rem;
  background: #fff;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
